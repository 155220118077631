export default {
    data() {
        return {
            newsItems: [
                {
                    id: '1',
                    author: '',
                    authorId: '1',
                    view: '70 views',
                    publishedAt: '2023-11-24',
                    title: '铝矾土磨粉用什么设备？如何做到环保制粉？',
                    body: `<p>铝土矿磨矿是将铝土矿磨成粉末的重要工业过程。铝土矿是一种重要的工业原料，广泛应用于陶瓷、玻璃、钢铁等领域。为了满足不同工业领域的需要，需要对铝土矿进行研磨，以获得更细、更均匀的粉体。</p>
                           <p class="img-p"><img src="/p1.jpg"></p>
                           <h5>铝矾土磨粉用什么设备？</h5>
                           <p>铝矾土磨粉过程中，需要使用专业的磨矿设备，如球磨机、砂磨机等。这些设备可以将铝土矿磨成不同细度的粉末，以满足不同领域的需要。同时，为了获得更好的研磨效果，需要定期对设备进行维护和保养，保证设备的正常运转。</p>
                           <p class="img-p"><img src="/p2.jpg"></p>
                           <h5>铝矾土磨粉如何做到环保？</h5>
                           <p>因为铝土矿在研磨过程中会产生大量的粉尘和噪音，对环境和人体健康都会产生一定的影响。因此，必须采取有效的环境保护和职业健康保护措施。例如，可以安装集尘器和消音器，以减少灰尘和噪音污染。</p>
                           <p class="img-p"><img src="/p3.jpg"></p>
                           <p></p>`,
                    category: 1
                },
                {
                    id: '2',
                    author: '找耐火材料网',
                    authorId: '2',
                    view: '70 views',
                    publishedAt: '2020-06-06',
                    title: '铝矾土资源储量及应用发展',
                    body: `
                        <p>铝矾土(aluminoussoil;bauxite)又称矾土或铝土矿，是一种土状矿物。一般呈白色或灰白色，也会因含铁而呈褐黄或浅红色。密度范围为3.9～4g/cm3，莫氏硬度1～3，质脆，不透明，不溶于水，可溶于硫酸、氢氧化钠溶液，极难熔化。在组成上，铝矾土是多种由含水氧化铝矿石组成的矿物的总称，其主要成分为Al2O3。如有的由一水软铝石(γ-AlO(OH))、一水硬铝石(α-AlO(OH))和三水铝石(Al2O3·3H2O)构成；有的是水铝石和高岭石(2SiO2·Al2O3·2H2O)相伴构成；有的以高岭石为主，且随着高岭石含量的增高，构成为一般的铝土岩或高岭石质黏土。铝矾土一般是外生作用或化学风化形成的，很少有纯矿物，总是包含一些杂质矿物，或多或少的会含有部分其他的黏土矿物、钛矿物、铁矿物及碎屑重矿物等等。在工业上，铝矾土主要用来做炼铝和生产耐火材料的原料。</p>
                        <p>目前，世界上已知的富存铝矾土的国家有49个。中国有丰富的铝矾土资源，在全国18个省、自治区、直辖市已查明铝土矿产地205处，其中大型产地72处(不包括台湾)。总储量约37亿吨，主要分布在山西、山东、河南、河北、贵州、广西、四川、辽宁、湖南等地。居世界前列，与巴西、澳大利亚、几内亚同属世界铝矾土资源大国。</p>
                        <p>虽然我国的铝矾土资源总储量较为丰富，但因大部分矿石结构不利于其在生产中的应用，且氧化铝的含量高的优质铝矾土矿储量比例较低，客观上对铝矾土矿的开发利用造成了不利的影响。表1为全国铝矾土矿硅铝比值分布的统计表。</p>
                        <p>由表1可知，我国的铝矾土矿的硅铝比值分布大部分在4~9之间，占储量总比例的73.96%，而硅铝比值较高的优质矿产总含量较低，硅铝比值>9的仅占总含量的18.62%。</p>
                        <p>我国储藏的铝矾土矿的矿物构成主要以一水硬铝石为主，合计储量占总储量的99%，其次有少量的以三水铝石为主的铝矾土矿，仅占全国总储量的1%。</p>
                        <p>较低的硅铝比以及以一水硬铝石为主的矿物结构限制了我国铝矾土资源的应用范围，进入21世纪以来我国逐渐成为铝产品生产的世界第一大国，铝矾土的需量极大，而相对有限的铝矾土资源更主要的应用方向为氧化铝及金属铝的生产，优质的铝矾土资源中能够应用于耐火材料方向的有限。近年来，由于缺乏统一的统筹管理，我国的铝矾土资源开发情况存在管理落后，只采不剥，乱采乱掘，只采富矿，摒弃贫矿等现象，矿石的利用率不到40%，大量的中低品位的铝矾土矿石和碎矿不能够被有效地利用，造成我国特等铝矾土矿几乎耗尽，高品质高品位的铝矾土矿资源日益枯竭。作为高铝耐火材料的重要原料，优质铝矾土矿物的匮乏严重制约了我国耐火材料工业的持续健康发展，引起相关人员的广泛关注。要解决相关的问题，首先在原矿的开采和加工过程中要加强统一管理，采取更为科学的选矿管理方案，合理配置资源。并且要大力发展中低品位铝矾土资源利用途径。</p>
                        <p>(1)铝矾土是生产高铝质耐火材料的主要原料。</p>
                        <p>目前，我国在将铝矾土资源应用与耐火材料生产方向时，主要有以下的利用途径：</p>
                        <p>制备矾土基均质料，从上世纪八九十年代开始，以钟香崇为代表的多名耐火材料方面的专家就针对利用中低品位的铝矾土进行矾土基均质料的生产进行了大量的研究工作，取得了大量的实验成果。我国在“八五”科技攻关中已取得突出成果，产品中Al2O3含量(w)可达85%以上，并且同一级别产品中Al2O3含量(w)误差不大于1%，但其成果直至本世纪初尚未转化推广。</p>
                        <p>(2)制备改性料：</p>
                        <p>即通过去除原料中的杂质或加入其他有益的氧化物以改善和优化高温性能(NymphodoraPapassiopi，2010)，通过这样方法生产的优质原料称为改性料，如电熔尖晶石系列合成料，烧结和电熔锆刚玉料等；其产品目前已投入使用，且已取得良好的使用效果。</p>
                        <p>(3)制备转型料：</p>
                        <p>以高铝矾土为原料，通过高温还原及氮化处理，使铝矾土中的Al2O3和SiO2转变或部分转变为AlON、SiAlON以及其与氧化物的复合材料。</p>
                        <p>矿物原料在作为耐火材料原料使用时，大部分需要经过预处理，以使其性能在高温下稳定，了解铝矾土在高温处理过程中的变化，对该材料的利用研究有重要的帮助。</p>
                        <p>以下为一水铝石一高岭石(D-K型)，即我国铝矾土矿的主要矿物类型的加热性能变化。</p>
                        <p>综合反应：</p>
                        <p class="img-p"><img src="/p4.jpg"></p>
                        <p>整个过程中，材料的体积收缩约20%。在整个反应过程中，首先发生水铝石的分解反应，水铝石在450℃~550℃时开始脱水，出现刚玉假象，当温度超过1100℃时，逐渐转变为刚玉相。高岭石在脱水后，首先形成污水偏高岭石，然后转变为莫来石相，析出非晶质的SiO2，在高温下转变为方石英。</p>
                        <p>在温度大于1200℃时，由水铝石转变而来的刚玉相可与高岭石转化为莫来石时析出的SiO2反应生成二次莫来石，其反应式为：</p>
                         <p class="img-p"><img src="/p5.jpg"></p>
                        <p>如果处理温度继续提升，在温度大于1400℃或1500℃时，材料会发生重结晶烧结作用，莫来石和刚玉的晶粒长大，气孔缩小消失，材料致密性迅速增加。</p>
                        <p></p>
                    `,
                    category: 1
                },
                {
                    id: '3',
                    author: '找耐火材料网',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '2021-01-14',
                    title: '耐火材料耐磨损性能的影响因素及测量、表征',
                    body: `
                        <p>耐磨损性能是耐火材料抵抗外界的磨损作用(如：摩擦、冲击和剥落等)的能力。水泥回转窑过渡带、循环流化床锅炉和干熄焦炉冷却段等对耐火材料的耐磨损性能均提出了一定的要求。</p>
                        <h5>1、影响材料耐磨损性能的因素</h5>
                        <p>材料本身的强度、硬度以及致密度，颗粒间的结合强度和微观结构对材料的耐磨损性能具有较大的影响。材料的服役温度对其耐磨损性能也有一定的影响。关于如何改善材料的耐磨损性能，对此大量的研究工作得到了开展。除了提升材料的致密度、降低材料的缺陷以外，其主要研究思路分为以下几个部分：(1)细化晶粒，进而提升材料整体的均匀性;(2)引入增强相，组分复合化形成复相材料;(3)相变增韧。一般来说，相同硬度下，强度高的材料的耐磨损性能能更加优异。</p>
                        <p>可以通过选择耐磨损性能好的材料、引入硬度高、强度大的第二相和选用合理的颗粒级配等来提升试样的耐磨损性能。</p>
                        <h5>2、耐磨损性能的测量与表征</h5>
                        <p>(1)摩擦磨损几种常见实验</p>
                        <p>1.滑动摩擦磨损试验：四球试验(图a)、销盘实验(图b)、环块试验(图c)、往复运动销盘试验(图d)。2.磨粒磨损试验(图e)。3.滚动磨损试验，环式滚动试验(图f);滚动四球试验(图g)4.冲蚀磨损试验(图h)。</p>
                        <p class="img-p"><img src="/p6.jpg"></p>
                        <p>(2)称重法</p>
                        <p>采用精密分析天平称量试样磨损前后的质量变化并计算其质量变化率来对耐磨损性能进行表征。这种方法简单，采用最普遍。</p>
                        <p>(3)磨损体积计量法</p>
                        <p>通过测得摩擦前后体积变化来大致计算出材料的磨损量。试样在磨损过程中，随着磨损的进行，试样表面的晶粒会逐渐剥落，进而试样的质量和体积均有所变化，通过测量磨损前后的体积变化来对耐磨损性能进行表征。可以通过光学测微计来测量试样磨损表面的划痕的宽度，采用下列公式计算出试样的磨损体积：</p>
                        <p class="img-p"><img src="/p7.jpg" style="width: 480px"></p>
                        <p>式中：V-磨损的体积(mm3)，R-环样的半径(mm)，b-磨痕的宽度(mm)，L-磨痕的长度(mm)。</p>
                        <p class="img-p"><img src="/p8.jpg" style="width: 100px"></p>
                        <p>式中：w-磨损率(mm3/m)，V-磨损体积(mm3)，D-滑动距离(m)。</p>
                        <h5>3、摩擦磨损的分类和机制</h5>
                        <p>磨损分为腐蚀磨损、磨粒磨损、黏着磨损和疲劳磨损。其机制为切削机制、粘着机制和疲劳机制。</p>
                        <p>3.1摩擦磨损的影响因素</p>
                        <p>(1)负载：一般负载越大，磨损越严重(图a)。</p>
                        <p>(2)转速：一般转速越大，磨损越严重(图b)。</p>
                        <p>(3)温度：低温磨损主要为疲劳、粘着和剥层机制。高温磨损的主要为氧化、粘着和剥层机制。</p>
                        <p>(4)磨料浓度：一般磨料浓度越大，磨损越严重(图c)。</p>
                        <p class="img-p"><img src="/p9.jpg"></p>
                        <p>3.2摩擦磨损的研究进展</p>
                        <p>黄伟九等人对陶瓷工具材料进行研究发现，其具有硬度高，耐磨损性能好，在高温下也拥有良好的化学稳定性，这些都广泛的应用于硬切削和高速切削材料领域，且未来的研究方向是在500nm以下的氧化铝或其他性能优异材料的陶瓷。</p>
                        <p>Pasaribu等人通过研究添加氧化铜来减少氧化铝和氧化锆陶瓷在工作中进行的磨损时发现，只有在氧化铝和氧化锆掺杂时，CuO才会显著降低摩擦系数，这是在微观结构中，CuO充当了“润滑剂”的作用，从拍摄的SEM图片中可以看出来磨损痕迹形成光滑的片状层。</p>
                        <p>Belmonte等人研究了SiC晶粒尺寸对Al2O3/SiC复合材料磨损的影响。结果表示，在一般条件下耐磨损性能随着SiC晶粒的增大而增大。</p>
                        <p></p>
                    `,
                    category: 1
                },
                {
                    id: '4',
                    author: '嵩峰铝矾土',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '2023-09-26',
                    title: '研磨级铝矾土：市场前景一览',
                    body: `
                        <p><strong>随着工业和科技的不断发展，研磨级铝矾土作为一种重要的工业原料，正扮演着越来越重要的角色。</strong>作为全球市场的新星，研磨级铝矾土在多个领域展现出了强劲的需求和发展潜力。本文将全面深入地探讨研磨级铝矾土市场的前景，为您呈现一个充满希望和机遇的黄金时代。</p>
                        <h5>一、行业概述</h5>
                        <p>研磨级铝矾土是由铝石矿石经过精细处理而得到的一种高性能的磨料材料。它具有化学稳定性好、硬度高、磨削效率高等特点，在航天航空、汽车制造、电子产品等领域都有广泛的应用。由于研磨级铝矾土的材质纯净且粒度均匀，它能更好地满足市场对高精度磨削的需求。</p>
                        <p class="img-p"><img src="/p10.jpg"></p>
                        <h5>二、市场需求分析</h5>
                        <p><strong>1.航天航空领域：</strong>随着人类对太空探索的深入，研磨级铝矾土在航天器制造中发挥着重要的作用。在高端航天领域，如火箭发动机、飞船结构等都需要精确磨削，而研磨级铝矾土正是能够满足这一需求的理想选择。</p>
                        <p><strong>2.汽车制造领域：</strong>近年来，全球汽车产业呈现出快速发展的势头。随之而来的是对汽车配件磨削质量的要求日益提高。研磨级铝矾土作为一种高性能的磨料材料，可以满足汽车零部件对精密磨削的需求，因此在汽车制造领域有着广泛的应用前景。</p>
                        <p><strong>3.电子产品领域：</strong>随着电子技术的飞速发展，智能手机、平板电脑等电子设备已成为人们生活不可或缺的一部分。而这些电子设备在制造过程中需要进行精细的磨削加工。研磨级铝矾土因其硬度高、压实性好等特点，被广泛应用于电子产品制造中的研磨工艺。</p>
                        <h5>三、市场竞争分析</h5>
                        <p><strong>目前，全球研磨级铝矾土市场竞争激烈，主要集中在中国、美国、澳大利亚等地。</strong>中国作为世界最大的研磨级铝矾土生产和出口国，拥有丰富的资源和发展经验，产能和产量均位居全球前列。而美国和澳大利亚则以技术和质量优势稳固其市场地位。但是，随着技术进步和市场扩大，新兴市场如印度、巴西等也开始崭露头角，市场竞争呈现出多元化的态势。</p>
                        <p class="img-p"><img src="/p11.jpg"></p>
                        <h5>四、发展趋势展望</h5>
                        <p><strong>在未来几年，研磨级铝矾土市场将保持稳定增长的势头。</strong>一方面，伴随全球工业领域的不断进步和技术革新，对高品质研磨级铝矾土的需求将不断增加。另一方面，在一些新兴市场的崛起和经济发展的推动下，研磨级铝矾土市场的规模将不断扩大。同时，研磨级铝矾土行业也将面临一些挑战，如环境保护和资源可持续利用问题，行业标准和质量控制等。</p>
                        <p><strong>总结起来，研磨级铝矾土市场前景广阔，充满了各种机遇和挑战。</strong>作为一种具有广泛应用前景的高性能磨料材料，研磨级铝矾土将在各个领域中发挥更大的作用。同时，行业参与者应抓住市场机遇，加强技术研发和产品创新，提高自身竞争力，才能在激烈的市场竞争中立于不败之地。</p>
                        <p></p>
                    `,
                    category: 1
                },
                {
                    id: '5',
                    author: '新华社',
                    authorId: '3',
                    view: '',
                    publishedAt: '2023-11-29',
                    title: '2024年我国将在地级及以上城市全面开展城市体检',
                    body: `
                        <p>新华社北京11月28日电（记者 王优玲）记者28日从住房城乡建设部了解到，自2024年开始，我国将在地级及以上城市全面开展城市体检，找出人民群众的急难愁盼问题和影响城市可持续发展的短板，推动系统治理“城市病”。</p>
                        <p>住房城乡建设部建筑节能与科技司相关负责人介绍，自2024年开始，住房城乡建设部将在地级及以上城市全面开展城市体检工作，把城市体检延伸到群众身边，将小区、社区、街区列为城市体检的基本单元，查找出社区养老服务设施、婴幼儿照护服务设施、公共活动场地、文化活动中心等设施配建不达标、功能不完善、服务不到位的问题，列出问题台账、录入信息平台并实施动态更新，为社区服务设施的科学规划、合理布局、精准嵌入提供有力支撑。</p>
                        <p>城市是有机生命体。和人的体检一样，城市也要定期体检。据介绍，2018年以来，住房城乡建设部指导样本城市、试点城市开展城市体检工作，主要目的是查找和解决群众急难愁盼问题和影响城市竞争力、承载力和可持续发展的短板弱项。目前，已建立从住房，到小区、社区，到街区，到城区、城市的城市体检指标体系，形成了“发现问题—解决问题—巩固提升”的城市体检工作机制。</p>
                        <p>近年来，住房城乡建设部聚焦为民、便民、安民服务，大力开展城镇老旧小区改造、完整社区建设、“国球进社区”等工作，引导物业服务企业提供普惠社区服务，努力建设让群众满意的“好社区”。2019年以来，住房城乡建设部指导各地在城镇老旧小区改造中，增设养老、托育等各类社区服务设施5.6万个，惠及居民3800多万户。2020年以来，积极推进完整社区建设，配建群众急需的“一老一小”服务设施，新建居住区达标配建养老服务设施比例从2020年的46.1%提高到2022年的83.2%。</p>
                        <p></p>
                    `,
                    category: 2
                },
                {
                    id: '6',
                    author: '新华社',
                    authorId: '3',
                    view: '',
                    publishedAt: '2023-11-28',
                    title: '支持民企发展，这些金融举措来了！',
                    body: `
                        <p>新华社记者吴雨、刘慧、张千千</p>
                        <p>民营经济发展壮大离不开资金支持。中国人民银行等八部门近日联合印发《关于强化金融支持举措 助力民营经济发展壮大的通知》，推出25条具体举措，持续加强民营企业金融服务。通过记者梳理，一起看看这些举措将如何支持民企融资。</p>
                        <h5>利好一：逐步提升民营企业贷款占比</h5>
                        <p>具体举措：银行业金融机构要制定民营企业年度服务目标；健全适应民营企业融资需求特点的组织架构和产品服务；合理提高民营企业不良贷款容忍度等。</p>
                        <p>记者解读：加大对民营企业的金融支持力度，首先体现在总量上。通过制定民营企业年度服务目标、提高服务民营企业相关业务在绩效考核中的权重等，一系列举措将引导金融机构加大对民营企业的金融支持力度，逐步提升民营企业贷款占比。同时通过不断优化信贷结构，科技创新、“专精特新”、绿色低碳、产业基础再造工程等重点领域的民营中小微企业将获得更有力的信贷支持。</p>
                        <h5>利好二：加大首贷、信用贷支持力度</h5>
                        <p>具体举措：银行业金融机构要积极开展首贷客户培育拓展行动；开发适合民营企业的信用类融资产品；推广“信易贷”模式等。</p>
                        <p>记者解读：缺乏抵押物、缺少可供参考的信用信息……这些因素常常令民营企业被金融机构拒之门外，支持民营企业需要从首贷和信用贷款“破冰”。金融机构在政策引导下，一方面将加强与发展改革和行业管理部门、工商联、商会协会对接合作，挖掘有市场、有效益、信用好、有融资需求的优质民营企业；另一方面将加强科技赋能，推出更多适合民营企业的信用类融资产品，持续扩大信用贷款规模。</p>
                        <h5>利好三：积极开展产业链供应链金融服务</h5>
                        <p>具体举措：银行业金融机构要积极探索供应链脱核模式；进一步完善中征应收账款融资服务平台功能；促进供应链票据规范发展等。</p>
                        <p>记者解读：部分民企单体信用资质较弱，大力发展产业链供应链金融，将民营小微企业嵌入整体产业链供应链，有助于部分解决民营企业信用评级较低、无抵押物等难题，进一步提高民营企业融资便利性、降低融资成本。</p>
                        <h5>利好四：畅通民营企业债券融资渠道</h5>
                        <p>具体举措：支持民营企业注册发行科创票据、科创债券、股债结合类产品、绿色债券、碳中和债券、转型债券等；推动民营企业债券融资支持工具扩容增量、稳定存量；鼓励和引导商业银行、保险公司、各类养老金、公募基金等机构投资者积极科学配置民营企业债券……</p>
                        <p>记者解读：针对民营企业债券认可度较低的痛点，进一步发挥债券融资支持工具的增信作用，同时鼓励和引导机构投资者加大对民企债券的配置，有助于降低民企债券融资门槛，扩大民营企业债券融资规模。特别是科技创新、绿色低碳等领域民营企业的资金需求，或将通过债券融资渠道进一步满足。</p> 
                        <h5>利好五：扩大优质民营企业股权融资规模</h5>
                        <p>具体举措：支持民营企业上市融资和并购重组；稳步拓展私募基金份额转让、认股权综合服务等创新业务试点；发挥股权投资基金支持民营企业融资的作用等。</p>
                        <p>记者解读：扩大优质民营企业股权融资规模需要更好发挥多层次资本市场的作用。可以预计，随着注册制改革走深走实，民营企业发行上市和再融资将更加市场化。与此同时，符合条件的民营企业赴境外上市也将更加顺畅。值得关注的是，通知中的一系列举措对于民营企业发展的更早期阶段也非常关注，对于更好发挥天使投资、创业投资以及区域性股权市场的融资支持作用进行了部署。</p> 
                        <h5>利好六：加大外汇便利化政策和服务供给</h5>
                        <p>具体举措：鼓励银行业金融机构开展跨境人民币“首办户”拓展行动；扩大高新技术和“专精特新”中小企业跨境融资便利化试点范围；优化跨境金融外汇特色服务等。</p>
                        <p>记者解读：加大外汇便利化政策和服务供给，可有力支持民营企业“走出去”“引进来”。通知提出“扩大高新技术和‘专精特新’中小企业跨境融资便利化试点范围”，有望惠及更多企业，助力科技自立自强。</p> 
                        <p>加大货币政策工具支持力度、强化财政奖补和保险保障、拓宽银行业金融机构资金来源渠道、健全风险分担和补偿机制、加大税收政策支持力度……为了让利好举措落实到位，相关部门还完善配套政策，强化正向激励，进一步提升金融机构服务民营经济的积极性。</p>
                        <p>记者了解到，中国人民银行等部门将指导金融机构抓紧落实通知要求，制定具体实施细则。同时，加强统计监测和政策效果评估，确保政策惠及民营企业。</p>
                        <p></p>
                    `,
                    category: 2
                },
                {
                    id: '7',
                    author: '新华社',
                    authorId: '3',
                    view: '',
                    publishedAt: '2023-11-27',
                    title: '2023年全国“宪法宣传周”活动将于12月1日启动',
                    body: `
                        <p>新华社北京11月26日电（记者 白阳）今年12月4日是第十个国家宪法日。记者26日从全国普法办获悉，2023年“宪法宣传周”活动时间为12月1日至7日。各地各部门可从实际出发，在时间上适当延展。</p>
                        <p>根据中央宣传部、司法部、全国普法办日前联合印发的通知，2023年全国“宪法宣传周”活动的主题是“大力弘扬宪法精神，建设社会主义法治文化”。重点宣传内容是：习近平新时代中国特色社会主义思想特别是习近平法治思想、习近平文化思想，宪法，社会主义法治文化，党的十八大以来全面依法治国取得的成就。</p>
                        <p>据悉，今年的重点活动安排包括：全国人大常委会办公厅牵头举办第十个国家宪法日有关活动；全国普法办发布习近平法治思想系列讲座视频；教育部、司法部在北京举办全国青少年学生法治教育实践示范基地开馆仪式，司法部支持教育部组织开展国家宪法日教育系统“宪法晨读”活动、《宪法伴我们成长》歌曲传唱等青少年学生宪法法治教育主题活动；文化和旅游部、司法部、全国普法办在国家图书馆举办社会主义法治文化专题展览及系列讲座；农业农村部、司法部在江苏省常熟市古里镇康博村举办“宪法进农村”主场活动；司法部、全国普法办在河北省举办“法律明白人”作用发挥工作试点地区交流会等。</p>
                        <p>通知要求，各地各部门要切实担负起新的文化使命，大力建设社会主义法治文化，提升公民法治素养。抓住领导干部这个“关键少数”，认真落实领导干部应知应会党内法规和国家法律清单制度。抓住青少年、网民等重点群体，持续深入开展宪法宣传教育。推动宪法在地铁站、机场、火车站等公共场所的广泛覆盖，营造浓厚氛围。进一步发挥“民主法治示范村（社区）”、农村学法用法示范户和“法律明白人”在化解基层矛盾纠纷中的作用，教育引导群众通过人民调解、行政复议、诉讼等途径依法维护自身权益，做到办事依法、遇事找法、解决问题用法、化解矛盾靠法。</p>
                        <p></p>
                    `,
                    category: 2
                },
                {
                    id: '8',
                    author: '人民日报',
                    authorId: '3',
                    view: '',
                    publishedAt: '2023-11-24',
                    title: '“跨省通办”加速跑 便利百姓“就近办”',
                    body: `
                        <p>近年来，公安机关全力推动户籍制度改革，“跨省通办”业务逐渐发展成熟，新生儿落户、首次申领居民身份证、全类型户口迁移等，能够在居住地办理的业务越来越多。</p>
                        <p>面对群众新要求新期待，“跨省通办”业务种类继续拓展，让更多群众享受改革便利。</p>
                        <p>“孩子出门急用身份证，不用回山东老家就能申请办理！”近日，常年居住在河北邯郸市馆陶县的秦先生将一面锦旗送到馆陶县行政服务大厅公安户政窗口，对窗口民警热心高效为孩子办理跨省身份证首次申领表示感谢。</p>
                        <p>为回应百姓诉求，近期一些地方在前期开通居民身份证补领换领“跨省通办”、首次申领居民身份证“全省通办”的基础上，试点拓展新生儿落户、首次申领居民身份证、全类型户口迁移等一系列“跨省通办”新业务，让更多群众享受户政管理改革带来的便利。</p>
                        <p>据统计，2023年4月至10月，全国累计办理“跨省通办”首次申领居民身份证57万张、户口迁移93万笔、户籍类证明44万笔，极大地方便了群众异地工作生活。</p>
                        <h5>新生儿登记，向线上数据流转方式转变</h5>
                        <p>“是不是要回老家才能给孩子上户口？”前不久，家住云南昆明市的张先生喜迎爱女，来到附近公安综合服务站窗口咨询。他户口远在安徽，如果按以往政策，需回户籍所在地派出所办理，十分麻烦。</p>
                        <p>“现在有了新生儿落户‘跨省通办’的便民新政策，在我们这儿可以一站办理了。”窗口民警马上通过服务系统联系上了张先生户籍地的公安机关。随后，两地公安机关将相关材料在线上平台审批流转后，成功帮张先生的女儿在安徽落户。</p>
                        <p>“这样真是方便多了！”不久，张先生便在公安综合服务站取走了女儿的户籍证明。</p>
                        <p>云南省公安厅相关负责人介绍，云南公安机关与各地公安机关对接协调，由线下协调方式向线上业务数据流转办理方式转变，今年累计办理新生儿落户“跨省通办”业务144件。</p>
                        <p>据了解，新生儿落户“跨省通办”在前期京津冀、江浙沪皖、川渝黔、闽赣、粤湘片区开展试点基础上，进一步在黑吉辽、鲁豫鄂、桂琼滇、蒙晋陕、甘青宁、藏疆和兵团片区相继开展试点，并逐步在全国范围全面推开。公安部相关负责人介绍，为高质量推进新生儿入户“跨省通办”，公安部积极推动与卫生健康部门实现出生医学证明数据跨部门共享，更好地辅助开展新生儿入户“跨省通办”工作。</p>
                        <h5>首次申领居民身份证，可直接在居住地办理</h5>
                        <p>在上海市就业、就学、居住的外省份户籍来沪人员首次申领居民身份证，可就近至派出所或进驻街镇社区事务受理中心的公安综合窗口申请办理；非山东省常住户口居民首次申领居民身份证，只需提供申请人居民户口簿和就业、就学、居住的相关证明材料，即可到当地公安机关办理……</p>
                        <p>针对群众反映强烈的跨省办理首次申请居民身份证的需求，各地公安机关按照公安部部署，纷纷出台具体细则办法，推动解决百姓烦恼。</p>
                        <p>万先生一家人在广东中山市三角镇生活居住多年，一直没回老家给孩子办理居民身份证。谁料因急事，孩子急需用身份证办理各类手续。正好赶上当地试点首次申领居民身份证“跨省通办”，解了他的燃眉之急。</p>
                        <p>广东省公安厅相关负责人介绍，广东省公安厅在已开通居民身份证首次申领“跨省通办”基础上，经过试点完善，于今年7月在全省新增户籍三项业务（即新生儿出生登记、户籍类证明和户口迁移）“跨省通办”业务。此外创新推出“一码通办”服务，提升效率，便利群众。</p>
                        <p>公安部相关负责人介绍，围绕首次申领居民身份证“跨省通办”，公安部坚持问题导向，集中技术攻坚，及时解决系统升级、信息流转等方面问题，确保“跨省通办”试点工作高效顺畅运行。截至今年10月底，31个省份已全面启动申领居民身份证“跨省通办”，累计办理居民身份证57万余张，获得群众广泛好评。</p>
                        <h5>推进全类型户口迁移业务“跨省通办”</h5>
                        <p>最近，在福建福州市仓山区政务服务中心公安服务综合窗口，有不少来办理户口迁移的市民。“以往只有特定的户口迁移类型可以‘跨省通办’，人才引进等情况仍需回户籍地办理迁出手续，费时费力。”邓女士去年从安徽来到福州工作，由于手续繁琐，一直未把户口迁到当地，如今听说户口迁移业务“跨省通办”覆盖了全领域，便来试试。</p>
                        <p>“在部分业务实现‘跨省通办’的基础上，我们将办理内容扩展到人才引进、祖孙投靠、子女投靠父母等全部21种户口迁移类型。外地市民仅需提供身份证、居民户口簿、迁移类型相关的凭证资料，例如工作调令、结婚证、毕业证、录取通知书等，就可以向迁入地公安机关提出落户申请，14个工作日内即可办结。”福州市公安局仓山分局治安大队民警苏张伽利介绍。</p>
                        <p>“跨省通办”打破地域阻隔，跑出户口迁移“加速度”。福建省公安厅治安总队四支队陈昕介绍，今年2月底以来，福建省启动了全域范围内全类型户口迁移“跨省通办”，进一步推动户籍业务由“回家办”到“就地办”，群众不再需要跨省往返奔波，即可一地办结户口迁移。截至目前，共办理户口迁移业务“跨省通办”4.1万笔。</p>
                        <p>公安部相关负责人介绍，截至9月，全国已有29个省份实现全类型户口迁移“跨省通办”，预计年底在全国范围内全面实施所有类型户口迁移业务“跨省通办”。</p>
                        <p>另外，除户口迁移、开具户籍类证明之外，首次申领居民身份证、申领临时居民身份证等户政业务也有望在今年年底实现全国通办，推动解决“多跑路”“折返跑”难题，方便群众生活工作。（记者 张天培）</p>
                        <p></p>
                    `,
                    category: 2
                },
                {
                    id: '9',
                    author: '光明日报',
                    authorId: '3',
                    view: '',
                    publishedAt: '2023-11-23',
                    title: '第二届全国人力资源服务业发展大会开幕',
                    body: `
                        <p>第二届全国人力资源服务业发展大会22日在广东深圳正式开幕。人力资源和社会保障部部长王晓萍、广东省副省长林涛、深圳市市长覃伟中出席大会开幕活动并讲话。人力资源和社会保障部副部长俞家栋主持开幕活动。</p>
                        <p>本届大会由人力资源和社会保障部、广东省人民政府主办，以“激发人力资源动能 汇聚强国建设力量”为主题，会期2天，包括开、闭幕活动及人力资源服务业高质量发展研讨、人力资源服务供需洽谈对接和展示、人力资源服务创新创业大赛和粤港澳大湾区人力资源服务技能大赛、粤港澳大湾区青年人才招聘等专项活动。</p>
                        <p>人力资源和社会保障部、广东省及深圳市有关单位负责同志，各省、自治区、直辖市及新疆生产建设兵团人力资源和社会保障厅（局）负责同志，港澳有关单位负责同志，专家学者、人力资源服务机构、重点用人单位等代表以及媒体记者参加大会开幕活动。（记者 任欢）</p>
                        <p></p>
                    `,
                    category: 2
                },
                {
                    id: '10',
                    author: '人民日报海外版',
                    authorId: '3',
                    view: '',
                    publishedAt: '2023-11-21',
                    title: '中国经济总体保持回升向好态',
                    body: `
                        <p>“10月份以来，经济总体保持回升向好态势。”国家发展和改革委员会政策研究室副主任、新闻发言人李超在日前举行的新闻发布会上表示，下一步，有关部门将精准有效实施宏观调控，加强政策预研储备，推动经济在质的有效提升和量的合理增长基础上实现高质量发展。</p>
                        <h5>经济大省发展持续向好</h5>
                        <p>前三季度，中国国内生产总值同比增长5.2%，各方面对四季度中国经济表现期待颇多。“看我国经济形势，不仅要看‘形’，更要看‘势’。”李超列举了10月以来经济运行多方面的积极态势。</p>
                        <p>具体来看：重点领域消费亮点较多。据中国汽车工业协会数据，10月国内汽车销量285.3万辆，再创当月历史同期新高，同比增长13.8%。</p>
                        <p>生产供给稳中有升。10月份全国规模以上工业增加值、服务业生产指数同比分别增长4.6%和7.7%，增速分别比上月加快0.1个和0.8个百分点。</p>
                        <p>地区经济保持恢复向好态势。浙江、江苏、四川等经济大省发展持续向好，西安、郑州、杭州、南京等重点城市经济运行稳步回升，对全国经济增长支撑作用增强。</p>
                        <p>市场预期保持平稳。尽管10月份制造业采购经理指数（PMI）有所回落，但从分项指标看，生产经营活动预期指数为55.6%，比上月上升0.1个百分点，连续4个月处于较高景气区间。</p>
                        <p>李超表示，要推动经济在质的有效提升和量的合理增长基础上实现高质量发展，将着力做到“四个持续”：持续扩大国内需求，加快组织实施新增发1万亿元国债项目建设，尽早形成实物工作量；持续支持企业高质量发展，加快清理妨碍统一市场和公平竞争的政策措施；持续做好岁末年初民生保障工作，维护重要民生商品市场供应和价格稳定；持续强化政策统筹协调，做好明年政策靠前发力的各项准备工作。</p>
                        <h5>居民消费潜力加速释放</h5>
                        <p>消费作为推进国民经济发展的重要一环，一直是政策关注的重点。10月，社会消费品零售总额同比增长7.6%，比上月加快2.1个百分点。</p>
                        <p>“从今年的‘黄金周’和刚刚过去的‘双11’情况看，居民消费潜力加速释放，呈现出新的特点和亮点。”李超说。</p>
                        <p>服务消费加快恢复，在中秋国庆假期出行热等带动下，接近居民消费支出“半壁江山”的服务消费继续保持较快增长。1—10月，服务零售额同比增长19%，比1—9月加快0.1个百分点。</p>
                        <p>线上消费热度较高，各大电商平台以及直播平台纷纷在“双11”期间推出活动，吸引广大消费者积极参与。国家邮政局监测数据显示，11月1日—11日，全国邮政快递企业共揽收快递包裹52.64亿件，同比增长23.22%。</p>
                        <p>新型消费引领潮流。绿色消费蔚然成风，新风空调、节能热水器等绿色健康产品销量大幅增长，城市漫步、城市骑行等绿色生活方式悄然走红。“国风”“国潮”消费火爆出圈，国货热度不断攀升，日化、美妆、食品等品类国货老品牌成了“香饽饽”。</p>
                        <p>李超表示，下一步，将落实落细已出台的促消费政策文件，提振重点领域消费、培育壮大新型消费。同时改善居民消费能力和预期，落实就业优先政策，多渠道增加城乡居民收入，提高中低收入群体消费能力。</p>
                        <h5>金融支持制造业力度加大</h5>
                        <p>去年9月起，国家发改委会同有关部门着力加强对制造业特别是民营制造业企业的融资支持和金融服务。李超介绍，在各方共同努力下，扩大制造业中长期贷款投放工作取得新进展。</p>
                        <p>制造业贷款结构持续优化。截至三季度末，制造业贷款余额同比增长17.18%，其中，制造业中长期贷款余额增长34.43%，有力推动制造业投资实现较快增长。</p>
                        <p>项目融资对接力度持续增强。从国家发改委推送给21家全国性银行的项目清单看，今年以来，清单内新签约项目2811个、签约金额6054.3亿元，新投放项目3047个、投放金额3523.8亿元，传统产业改造升级、生物医药、电子信息等领域签约和投放力度明显增大。</p>
                        <p>对民营制造业企业的支持力度进一步加大。民营企业申报数量多、项目质量高、资金落地快，前三季度民营制造业项目审核通过率高于全部项目审核通过率1.5个百分点，签约投放落地率高于全部项目落地率8.3个百分点。</p>
                        <p>此外，随着促进民营经济发展壮大的政策举措陆续细化落实，民营经济发展形势边际改善，9月新登记注册私营企业户数同比增长18.1%，比上月加快8.4个百分点。1—10月，民营企业进出口增长6.2%；扣除房地产开发投资的民间投资增长9.1%。</p>
                        <p>“我们将持续加大对制造业企业特别是民营制造业企业的金融支持力度，持续提升服务现代化产业体系的能力和水平，为推动制造业高端化、智能化、绿色化发展提供有力支撑。”李超说。（记者 李婕）</p>
                        <p></p>
                    `,
                    category: 2
                },
            ],
        }
    }
}
