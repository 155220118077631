<template>
    <Layout :footer-style="1">
        <!-- Start Slider Area -->
        <div class="slider-area bg-transparent slider-style-1">
          <slider ref="slider" :options="options">
            <!-- slideritem wrapped package with the components you need -->
            <slideritem v-for="(item,index) in someList" :key="index" :style="item.style">
              <img class="slider-img" :src="item.image" alt="">
            </slideritem>
            <!-- Customizable loading -->
            <div slot="loading">loading...</div>
          </slider>
<!--          <div class="control">-->
<!--            <div class="prev" @click="slidePre">-->
<!--              <img alt="前一张" src="@/assets/images/icons/arrow-left.png" />-->
<!--            </div>-->
<!--            <div class="next" @click="slideNext">-->
<!--              <img alt="后一张" src="@/assets/images/icons/arrow-right.png" />-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <!-- End Slider Area -->

        <!-- Start Our Product -->
        <div class="rn-section-gap" id="products">
            <div class="wrapper">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="产品信息"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-4 mt--60"
                         v-for="(page, index) in products"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="product-card">
                            <router-link :to="page.url">
                                <div class="thumbnail">
                                    <img :src="page.image" alt="Corporate images"/>
                                    <span v-if="page.badge" class="label-badge">{{ page.badge }}</span>
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link :to="page.url">{{ page.title }}</router-link>
                            </h4>
                        </div>
                    </div>
                    <div class="col-12 mt--120 text-center">
                      <router-link class="btn-default btn-medium btn-border round btn-icon" to="/product">
                        查看更多
                        <Icon name="arrow-right" size="20" icon-class="icon"/>
                      </router-link>
                    </div>
                  </div>
            </div>
        </div>
        <!-- End Our Product -->

        <!-- Start Inner Pages -->
        <div class="rn-inner-pages rn-section-gap rn-gray-bg">
            <div class="wrapper">
                <div class="row row--15">
                    <div class="col-12">
                        <SectionTitle
                            text-align="center"
                            title="企业介绍"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="about-cards">
                    <div class="about-card"
                         v-for="(page, index) in innerPages"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="content">
                            <router-link :to="page.url">
                              <img class="thumbnail" :src="page.image" alt="Corporate images"/>
                            </router-link>
                            <div class="title">
                              <div class="text">
                                {{ page.title }}
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Inner Pages -->

        <div class="rn-section-gap">
          <div class="wrapper">
            <div class="row">
              <div class="col-12">
                <SectionTitle
                    text-align="center"
                    title="新闻资讯"
                    data-aos="fade-up"
                />
              </div>
            </div>
            <div class="row row--15 mt--40">
              <div class="col-6">
                <img src="../assets/images/index/newss.png" alt="">
              </div>
              <div class="col-6">
                <ul class="news-list">
                  <li v-for="(item, index) in newsItems" :key="`${index}`">
                      <template v-if="item.category === 1">
                        <span class="left-content">
                          <router-link :to="`/news/${item.id}`">
                          {{ item.title }}
                          </router-link>
                        </span>
                        <span class="right-content">{{ item.publishedAt }}</span>
                      </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import { slider, slideritem } from 'vue-concise-slider'
    import NewsItemMixin from '@/mixins/NewsItemMixin'
    export default {
        name: 'Index',
        mixins: [NewsItemMixin],
        components: { Icon, Layout, Separator, SectionTitle, slider, slideritem },
        data() {
            return {
                products: [
                    {
                        url: '/product',
                        image: require('@/assets/images/index/u2.jpg'),
                        title: '熟块料',
                        badge: '',
                    },
                  {
                    url: '/product',
                    image: require('@/assets/images/index/u1.jpg'),
                    title: '料面',
                    badge: '',
                  },
                  {
                    url: '/product',
                    image: require('@/assets/images/index/u3.jpg'),
                    title: '铁料',
                    badge: '',
                  }
                ],
                innerPages: [
                    {
                        id: '1',
                        url: '/company',
                        image: require('@/assets/images/index/about1.jpg'),
                        title: '安全厂区'
                    },
                    {
                        id: '2',
                        url: '/company',
                        image: require('@/assets/images/index/about2.jpg'),
                        title: '一流设备'
                    },
                    {
                        id: '3',
                        url: '/company',
                        image: require('@/assets/images/index/about3.jpg'),
                        title: '储备充裕'
                    },
                    {
                        id: '4',
                        url: '/company',
                        image: require('@/assets/images/index/about4.jpg'),
                        title: '服务完善'
                    }
                ],
                someList:[
                  {
                    html: 'slide1',
                    image: require('@/assets/images/index/slider/01.jpg'),
                  }
                ],
                //Sliding configuration [obj]
                options: {
                  currentPage: 0,
                  thresholdDistance:500,
                  thresholdTime:100,
                  autoplay:5000,
                  loop:true,
                  slidesToScroll:1,
                  timingFunction: 'ease',
                  speed: 1000
                }
            }
        },
      mounted () {
      },
      methods: {
        // slideNext () {
        //   this.$refs.slider.$emit('autoplayStop')
        //   this.$refs.slider.$emit('slideNext')
        //   this.$refs.slider.$emit('autoplayStart', 3000)
        // },
        // slidePre () {
        //   this.$refs.slider.$emit('autoplayStop')
        //   this.$refs.slider.$emit('slidePre')
        //   this.$refs.slider.$emit('autoplayStart', 3000)
        // }
      }
    }
</script>
<style lang="scss" scoped>
.slider-area {
  max-width: 1920px;
  margin: 0 auto;
  .slider-img {
    max-width: 1920px;
  }
  .control {
    display: none;
    justify-content:space-between;
    width: 90%;
    left: 5%;
    position: absolute;
    top: 355px;
    z-index: 1999;
    opacity: 0.5;
    .prev {
      cursor: pointer;
    }
    .next{
      cursor: pointer;
    }
  }
  &:hover .control {
    display: flex;

    &:hover {
      opacity: 1;
    }
  }
}
.product-card {
  text-align: center;
  transition: 0.3s;
  position: relative;
  padding: 0 20px;
  .thumbnail {
    position: relative;

    img {
      border-radius: 10px;
      width: 100%;
    }
  }
  .title {
    margin: 20px 0 0;
    font-size: 22px;
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;

  }
  &:hover {
    transform: translateY(-5px);
  }
}
.about-cards {
  padding: 0 15px;
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .about-card {
    width: 525px;
    height: 400px;
      margin: 0 auto;
    .content {
      position: relative;
      .thumbnail {
        width: 525px;
        height: 400px;
      }
      .title {
        height: 400px;
        width: 525px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .text {
          background-color: rgb(239,236,233,0.8);
          margin: 0 auto;
          font-size: 28px;
          padding: 12px 20px;
          font-weight: bolder;
          color: #000000;
        }
      }
    }
  }
}
.news-list {
  margin-bottom: -10px;
  padding-right: 0;
  margin-top: -10px;
  padding-left: 10px;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    .left-content {
      margin-right: auto;
      width: 75%;
      align-items: center;
      display: flex;
      a {
        font-size: 18px;
        color: #000000;
        white-space: nowrap;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          text-decoration: none;
          -webkit-tap-highlight-color: transparent;
          color: #0055a2;
        }
      }
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #9d9d9d;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
    .right-content {
      margin-left: auto;
      width: 20%;
      font-size: 16px;
      color: #9d9d9d;
    }
  }
}
</style>
