<template>
    <div :class="{'header-transparent-with-topbar': data.headerTransparency}">
        <div v-if="data.showTopHeader && data.topbarStyle === 2"
             class="header-top-bar">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-6">
                      <Logo/>
                    </div>
                    <div class="col-6">
                        <div class="header-right slogan">
                          <img src="@/assets/images/slogan.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Start Header Area -->
        <header :class="[`rn-header header-default header-blue header-not-transparent header-sticky ${data.headerClass}`]">
            <div class="container position-relative">
                <div class="row align-items-center row--0">
                  <div class="col-12 position-static">
                    <div class="header-left d-flex">
                      <nav class="mainmenu-nav">
                        <Nav/>
                      </nav>
                    </div>
                  </div>
                </div>
            </div>
        </header>
        <!-- End Header Area -->
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import AppFunctions from '../../../helpers/AppFunctions'
    import Nav from './Nav'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Header',
        props: {
            data: {
                default: null
            }
        },
        components: {Logo, Nav, Icon},
        data() {
            return {
                AppFunctions
            }
        },
        methods: {
            toggleStickyHeader() {
                const scrolled = document.documentElement.scrollTop;
                if (scrolled > 100) {
                    AppFunctions.addClass('.header-default', 'sticky');
                } else if (scrolled <= 100) {
                    AppFunctions.removeClass('.header-default', 'sticky');
                }
            }
        },
        created() {
            window.addEventListener('scroll', this.toggleStickyHeader);
        },
        mounted() {
            this.toggleStickyHeader();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.toggleStickyHeader);
        }
    }
</script>
<style lang="scss" scoped>
.header-blue {
  background-color: #157cc9 !important;
  .mainmenu-nav {
    .mainmenu {
      li {
        a {
          color: #ffffff !important;
        }
      }
    }
  }
}
.slogan{
  img {
      height: 30px;
  }
}
</style>
