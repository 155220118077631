<template>
    <Layout>
        <div class="service-area company-banner">
          <div class="container" style="max-width: 1920px;padding: 0;">
            <img class="banner-image" src="@/assets/images/data/banner.png">
          </div>
        </div>
        <div v-if="false" class="slider-area slider-style-1 mt--40">
          <div class="container">
            <h3>产品介绍</h3>
          </div>
        </div>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 bg-transparent mt--80 mb--80 pt--40">
            <div class="container">
              <div class="wrapper">
              <div class="row align-items-center">
                  <div class="col-5 order-1">
                    <div class="frame-image" data-aos="fade-up" data-aos-delay="100">
                      <img src="@/assets/images/data/p1.jpg" alt="88号规格"/>
                    </div>
                  </div>
                  <div class="col-7 order-2">
                      <div class="inner text-left ml--40 pt--30" data-aos="fade-up" data-aos-delay="150">
                          <h4 class="title">
                            山西铝矾土最新行情（2023-09-10）
                          </h4>
                          <div class="content">
                            <table>
                              <tr>
                                <th>产品名称</th>
                                <th>规格</th>
                                <th>品质</th>
                                <th>产地</th>
                                <th>价格（每吨）</th>
                              </tr>
                              <tr>
                                <td>铝矾土</td>
                                <td>铁皮窑块料</td>
                                <td>AI2O3≥85%</td>
                                <td>阳泉</td>
                                <td>8888¥</td>
                              </tr>
                              <tr>
                                <td>铝矾土</td>
                                <td>铁皮窑块料</td>
                                <td>AI2O3≥85%</td>
                                <td>阳泉</td>
                                <td>8888¥</td>
                              </tr>
                              <tr>
                                <td>铝矾土</td>
                                <td>铁皮窑块料</td>
                                <td>AI2O3≥85%</td>
                                <td>阳泉</td>
                                <td>8888¥</td>
                              </tr>
                              <tr>
                                <td>铝矾土</td>
                                <td>铁皮窑块料</td>
                                <td>AI2O3≥85%</td>
                                <td>阳泉</td>
                                <td>8888¥</td>
                              </tr>
                            </table>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'

    export default {
        name: 'Company',
        components: { Layout },
        data() {
            return { }
        }
    }
</script>
<style lang="scss" scoped>
.company-banner {
  margin: 0 auto;
  .banner-image {
    width: 1920px;
  }
}
.frame-image {
  padding: 0;
  background: none;
  img {
    border-radius: 10px;
  }
}
.content{
  font-size: 16px;
}
table {
  table-layout: fixed;
  border-collapse: separate;
  th, td {
    text-align: center;
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
  }
  td {
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    &:first-child {
      border-left: 1px solid #ebeef5;
    }
  }
  th {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    &:nth-child(n+2) {
      border-left: none;
    }
  }
}
</style>
