import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Company from '../views/Company'
import Product from '../views/Product'
import Data from '../views/Data'
import NewsList from '../views/NewsList'
import NewsDetail from '../views/NewsDetail'
import ContactPage from '../views/ContactPage'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/company',
        name: 'Company',
        component: Company,
        meta: {
            title: '企业风采',
        },
    },
    {
        path: '/product',
        name: 'Product',
        component: Product,
        meta: {
            title: '产品介绍',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: '联系我们',
        },
    },
    {
        path: '/news',
        name: 'News List',
        component: NewsList,
        meta: {
            title: '新闻资讯',
        },
    },
    {
        path: '/news/:id',
        name: 'News Detail',
        component: NewsDetail,
        meta: {
            title: '新闻资讯',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title + ' - 纬达矾石';
    next();
    window.scrollTo(0, 0)
});

export default router
