<template>
  <div class="breadcrumb-area mt--20">
    <div class="container">
      <div class="wrapper">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <ul class="page-list">
              <li class="rn-breadcrumb-item"><router-link :to="rootUrl">首页</router-link></li>
              <li v-if="currentUrl" class="rn-breadcrumb-item active">{{ currentUrl }}</li>
              <template v-if="rs">
                <li v-for="(r, idx) in rs" class="rn-breadcrumb-item"
                    :class="{'active': idx === rs.length - 1}"
                >
                  <template v-if="r.url">
                    <router-link :to="r.url">{{ r.name }}</router-link>
                  </template>
                  <template v-else>
                    {{ r.name }}
                  </template>
                </li>
              </template>
            </ul>
          </div>
        </div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    title: {
      type: String
    },
    rootUrl: {
      type: String,
      default: '/'
    },
    parentUrl: {
      type: String,
      default: '首页'
    },
    currentUrl: {
      type: String,
      default: ''
    },
    rs: {
      type: Array,
      default: []
    }
  }
}
</script>
