<template>
    <div class="logo">
        <router-link :to="url">
          <img :src="lightLogo" alt="纬达矾石"/>
          <div class="logo-name">纬达矾石</div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            url: {
                type: String,
                default: '/'
            },
            lightLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo.png`)
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.logo-name {
  display: inline-block;
  font-size: 28px;
  color: #000000;
  font-weight: bolder;
  line-height: 60px;
  height: 60px;
  margin-left: 12px;
  vertical-align: middle;
}
</style>
