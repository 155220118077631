<template>
    <Layout>
      <div class="service-area company-banner">
        <div class="container" style="max-width: 1920px;padding: 0;">
          <img class="banner-image" src="@/assets/images/contact/banner.jpg">
        </div>
      </div>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            title="联系我们"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-4">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="headphones" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">电话</h4>
                                            <p><a href="tel:13903535528">张总 18635334747</a></p>
                                            <p><a href="tel:18635334747">赵总 13903535528</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-4">
                                  <div class="rn-address">
                                    <div class="icon">
                                      <Icon name="map-pin" :size="40"/>
                                    </div>
                                    <div class="inner">
                                      <h4 class="title">地址</h4>
                                      <p>山西省阳泉市盂县孙家庄镇</p><p style="height: 30px"> </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-4">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">邮箱</h4>
                                            <p><a href="mailto:wdfs3949@163.com">wdfs3949@163.com</a></p>
                                            <p style="height: 30px"> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 20px;">
                                <img src="@/assets/images/contact/map.jpg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "../components/icon/Icon";
    import GoogleMap from "../components/elements/contact/GoogleMap";
    import ContactForm from "../components/elements/contact/ContactForm";

    export default {
        name: 'ContactPage',
        components: {ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout}
    }
</script>
