<template>
    <Layout>
      <div class="service-area company-banner">
        <div class="container" style="max-width: 1920px;width:100%; padding: 0;">
          <img class="banner-image" src="@/assets/images/news/banner.jpg">
        </div>
      </div>

        <div class="rn-blog-area mb--80 mt--40">
            <div class="container">
              <div class="wrapper">
                <div class="row row--20">
                    <div class="col-5">
                        <img src="@/assets/images/news/list.png" style="border-radius: 12px;">
                    </div>
                    <div class="col-7">
                        <div class="category-list">
                            <div class="category" :class="{'selected': category===1}" @click="show(1)">
                                <span class="left-content">行业资讯</span>
                            </div>
                            <div class="category" :class="{'selected': category===2}" @click="show(2)">
                                <span class="left-content">盂县热点</span>
                            </div>
                        </div>
                      <ul v-if="newsData.length > 0" class="news-list">
                        <li v-for="(item, index) in newsData" :key="`${index}`">
                          <span class="left-content">
                            <router-link :to="`/news/${item.id}`">
                            {{ item.title }}
                            </router-link>
                          </span>
                          <span class="right-content">{{ item.publishedAt }}</span>
                        </li>
                      </ul>
                      <div v-else class="news-list">暂无数据</div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import NewsItemMixin from '@/mixins/NewsItemMixin'

    export default {
      name: 'NewsList',
      components: { Layout, Breadcrumb},
      mixins: [NewsItemMixin],
      data() {
        return {
          newsData: [],
          category: 1
        }
      },
      methods: {
          show(cat) {
            this.category = cat;
            this.newsData = this.newsItems.filter((item) => item.category === cat);
          }
      },
      mounted() {
        this.show(1)
      }
    }
</script>
<style lang="scss" scoped>
.news-list {
  margin-bottom: -10px;
  padding-right: 0;
  margin-top: 24px;
  padding-left: 10px;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    .left-content {
      margin-right: auto;
      width: 75%;
      align-items: center;
      display: flex;
      a {
        font-size: 18px;
        color: #000000;
        white-space: nowrap;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          text-decoration: none;
          -webkit-tap-highlight-color: transparent;
          color: #0055a2;
        }
      }
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #9d9d9d;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
    .right-content {
      margin-left: auto;
      width: 20%;
      font-size: 16px;
      color: #9d9d9d;
    }
  }
}
.banner-image {
    width: 100%;
    max-width: 1920px;
}
.category-list {
    width: 100%;
    display: flex;
    .category {
        font-size: 20px;
        font-weight: bolder;
        width: 120px;
        text-align: center;
        padding: 4px;
        cursor: pointer;
        color: #0055a2;
        border-bottom: 2px solid #0055a2;
        &.selected,&:hover {
            background-color: #0055a2;
            color: white;
        }
    }
}
</style>
