<template>
    <Layout>
        <div class="service-area company-banner">
          <div class="container" style="max-width: 1920px;padding: 0;">
            <img class="banner-image" src="@/assets/images/company/banner.jpg">
          </div>
        </div>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 bg-transparent mt--80">
            <div class="container">
                <div class="wrapper">
                  <div class="row align-items-center">
                    <div class="col-5 order-1">
                      <div class="frame-image" data-aos="fade-up" data-aos-delay="100">
                        <img src="@/assets/images/company/info.jpg" alt="企业信息"/>
                      </div>
                    </div>
                    <div class="col-7 order-2">
                      <div class="inner text-left ml--40" data-aos="fade-up" data-aos-delay="150">
                        <h3 class="title">
                          企业信息
                        </h3>
                        <div class="content">
                          盂县纬达矾石加工有限公司，成立于2009年，位于山西省阳泉市，是一家以从事非金属矿物制品业为主的企业，主要业务涵盖矾石加工、耐火材料制品制造销售等等。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <div class="slider-area slider-style-1 rn-gray-bg mt--80 pt--80 pb--80">
          <div class="container">
            <div class="wrapper">
              <div class="row align-items-center">
                <div class="col-5 order-2">
                  <div class="frame-image" data-aos="fade-up" data-aos-delay="100">
                    <img src="@/assets/images/company/factory.png" alt="企业信息"/>
                  </div>
                </div>
                <div class="col-7 order-1">
                  <div class="inner text-left mr--40" data-aos="fade-up" data-aos-delay="150">
                    <h3 class="title">
                      厂区
                    </h3>
                    <div class="content">
                      厂于位于山西省阳泉市盂县境内，占地面积约100亩，厂区内设施完善、设备先进，可以完全满足铝矾石原料加工、生产、储备的需要，欢迎行业相关企业来参观学习。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="slider-area slider-style-1 bg-transparent mt--80">
          <div class="container">
            <div class="wrapper">
              <div class="row align-items-center">
                <div class="col-5 order-1">
                  <div class="frame-image" data-aos="fade-up" data-aos-delay="100">
                    <img src="@/assets/images/company/store.jpg" alt="企业信息"/>
                  </div>
                </div>
                <div class="col-7 order-2">
                  <div class="inner text-left ml--40" data-aos="fade-up" data-aos-delay="150">
                    <h3 class="title">
                      仓库
                    </h3>
                    <div class="content">
                      目前有室内、室外标准化铝矾土原料、生产物、废料仓库及集中堆放处理区域30余个，完全满足生产、储备需要。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="slider-area slider-style-1 rn-gray-bg mt--80 pt--80 pb--80">
        <div class="container">
          <div class="wrapper">
            <div class="row align-items-center">
              <div class="col-5 order-2">
                <div class="frame-image" data-aos="fade-up" data-aos-delay="100">
                  <img src="@/assets/images/company/workshop.jpg" alt="加工车间"/>
                </div>
              </div>
              <div class="col-7 order-1">
                <div class="inner text-left mr--40" data-aos="fade-up" data-aos-delay="150">
                  <h3 class="title">
                      加工厂房
                  </h3>
                  <div class="content">
                      铝矾土矿煅烧车间设计年产高铝矾土熟料数万吨。 建有多条高精度回转窑，煅烧特级矾土块料 (20mm~50mm)，熟料 Al2O3 可高达~88.5%，TiO2 含量控制在3.5% ~4.0%，R2O 0.4% ~ 0.5%，Fe2O3 2.0%~2.5%，熟料体积密度可达3.25g.cm-3。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="slider-area slider-style-1 bg-transparent mt--80 mb--80">
        <div class="container">
          <div class="wrapper">
            <div class="row align-items-center">
              <div class="col-5 order-1">
                <div class="frame-image" data-aos="fade-up" data-aos-delay="100">
                  <img src="@/assets/images/company/team.jpg" alt="团队"/>
                </div>
              </div>
              <div class="col-7 order-2">
                <div class="inner text-left ml--40" data-aos="fade-up" data-aos-delay="150">
                  <h3 class="title">
                    团队
                  </h3>
                  <div class="content">
                      我国各地高铝矾土贮存状况及矿石结构差异较大，在建设大型高铝矾土煅烧与矿山基地之前， 首先要搞清楚机械化采矿能否实现分级开采以满足矿石分级入炉煅烧的要求。对人员、机械都有比较高的要求，我厂数十年来不断改进工艺加强人员培训学习，有着可靠稳定的安全生产团队，值得信赖。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'

    export default {
        name: 'Company',
        components: { Layout },
        data() {
            return { }
        }
    }
</script>
<style lang="scss" scoped>
.company-banner {
  margin: 0 auto;
  .banner-image {
    width: 1920px;
  }
}
.frame-image {
  padding: 0;
  background: none;
  img {
    border-radius: 10px;
  }
}
.content{
    font-size: 18px;
}
</style>
