<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
          <div class="copyright-right text-center">
            <p class="copyright-text">
                Copyright © 2010-{{ new Date().getFullYear() }} 盂县纬达矾石加工有限公司 All Rights Reserved <a href="https://beian.miit.gov.cn" target="_blank">晋ICP备2023026103号-1</a>
                <br>技术支持：<a href="https://cheright.com" target="_blank">北京中炘信息技术有限公司</a>
            </p>
          </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>
