<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two rn-section-gap">
            <div class="footer-top">
                <div class="container">
                    <div class="wrapper">
                      <div class="row">
                        <div class="col-6">
                          <div class="rn-footer-widget">
                            <h4 class="title">相关链接</h4>
                            <div class="inner">
                              <ul class="footer-link link-hover">
                                <li>
                                  <a href="https://baijiahao.baidu.com/s?id=1668737786592326754&wfr=spider&for=pc" target="_blank">铝矾土资源储量及应用发展</a>
                                </li>
                                <li>
                                  <a href="https://baike.baidu.com/item/%E9%93%9D%E5%9C%9F%E7%9F%BF/2411680?fromtitle=%E9%93%9D%E7%9F%BE%E5%9C%9F&fromid=10449936&fr=aladdin" target="_blank">铝矾土介绍</a>
                                </li>
                                <li>
                                  <a href="https://www.chinairn.com/search.aspx?search=%25u94DD%25u571F%25u77FF&type=%25u5168%25u90E8&bd_vid=11768992377582534764" target="_blank">中国产业研究院</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="rn-footer-widget">
                            <h4 class="title">联系我们</h4>
                            <div class="inner">
                              <div class="item">
                                  <div style="display: flex;flex-direction: column;">
                                      <div>电话：张总 18635334747</div>
                                      <div style="margin-left: 48px">赵总  13903535528</div>
                                  </div>
                              </div>
                              <div class="item">邮箱：wdfs3949@163.com</div>
                              <div class="item">地址：山西省阳泉市盂县孙家庄镇</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- End Footer Area  -->

        <Copyright v-if="data.showCopyright"/>

        <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Footer',
        components: { Logo, ScrollTop, Icon, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>
