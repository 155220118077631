<template>
    <ul class="mainmenu">
        <li class="link-hover"><router-link to="/">首页</router-link></li>
        <li class="link-hover"><router-link to="/company">企业风采</router-link></li>
        <li class="link-hover"><router-link to="/product">产品介绍</router-link></li>
        <li class="link-hover"><router-link to="/news">新闻资讯</router-link></li>
        <li class="link-hover"><router-link to="/contact">联系我们</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>
