<template>
    <Layout>
      <Breadcrumb :rs="rs" />
        <div class="rn-blog-details-area">
            <div class="post-page-banner mt--40">
                <div class="container">
                    <div class="wrapper">
                      <div class="row">
                        <div class="col-12">
                          <div class="content text-center">
                            <div class="page-title">
                              <h2 v-html="post.title"/>
                            </div>
                            <ul class="rn-meta-list">
                              <li v-if="post.author">
                                <Icon name="user" class="size-20"/>
                                <span>{{ post.author }}</span>
                              </li>
                              <li>
                                <Icon name="calendar" class="size-20"/>
                                <span>{{ post.publishedAt }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="wrapper">
                      <div class="row">
                        <div class="col-12">
                          <div class="content">
                            <p v-html="post.body"/>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Icon from '../components/icon/Icon'
    import NewsItemMixin from "@/mixins/NewsItemMixin";

    export default {
        name: 'NewsDetail',
        components: {Icon, Layout, Breadcrumb},
        mixins: [NewsItemMixin],
        data() {
            return {
              id: this.$route.params.id,
              post: {},
              rs: [{name: '新闻资讯', url:'/news'}]
            }
        },
        methods: {
            getPost(postId) {
                this.post = this.newsItems.find(item => item.id == postId);
                if(this.post) {
                  this.rs.push({name: this.post.title})
                }
            }
        },
        mounted() {
            this.getPost(this.id);
        }
    }
</script>
<style lang="scss">
.img-p {
    text-align: center;
    img {
        width: 520px;
        margin: 0 auto;
    }
}
</style>
